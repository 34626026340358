export const addSuborganizationConstants = {
	MIN_USERS: 1,
	MIN_EMAILS: 0,
	MIN_SMS: 0,
	MIN_WHATSAPP: 0,
	MIN_LANDING_PAGES: 0,
	DEFAULT_USERS_STEP: 1,
	DEFAULT_EMAILS_SLIDER_STEP: 1000,
	DEFAULT_SMS_STEP: 1000,
	DEFAULT_LANDING_PAGES_STEP: 5,
	MAX_LIMIT: -1,
	CONSUMABLE_ENABLED: 1,
	MILLION: 1000000,
	INCREASE_CONSUMABLE: 'increase_consumable',
	DECREASE_CONSUMABLE: 'decrease_consumable',
	USERS_NO_LIMIT_TOGGLE: 'users_no_limitation',
	EMAILS_NO_LIMIT_TOGGLE: 'emails_no_limitation',
	LANDING_PAGES_NO_LIMIT_TOGGLE: 'landing_pages_no_limitation',
	EXTERNAL_FEEDS_ENABLE_TOGGLE: 'external_feeds_enable_toggle',
	WP_ENABLE_TOGGLE: 'wp_enable_toggle',
	SMS_NO_LIMIT_TOGGLE: 'sms_no_limitation',
	WHATSAPP_NO_LIMIT_TOGGLE: 'whatsapp_no_limitation',
};

export const appManagementConstants = {
	ENABLE: 'enable',
	DISABLE: 'disable'
};

export const smsCalculatorConstants = {
	MARKETING_CODE_MASTER_PLAN: 'm',
	COUNTRY_RUSSIA: 'Russia',
	COUNTRY_RUSSIAN_FEDERATION: 'Russian Federation',
};
