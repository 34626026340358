import { initGoogleTagManager } from '@dtsl/jsutils';
import Cookies from 'js-cookie';

export function padTo2Digits(num) {
	return num.toString().padStart(2, '0');
}

export function formatDate(date) {
	return [
		padTo2Digits(date.getDate()),
		padTo2Digits(date.getMonth() + 1),
		date.getFullYear()
	].join('-');
}

export function maskKey(str) {
	return str.toString().substr(-6).padStart(16, '*');
}

export const initialiseGTM = (isEnterpriseV2, userid, email, accountId) => {
	const userIdsToIgnore = GTM_DISABLE_IDS.split(',').map((id) => +id);
	if (userIdsToIgnore.includes(userid)) return;

	if (isEnterpriseV2 && ['staging', 'production'].includes(APP_ENV)) {
		initGoogleTagManager(GTM_ID, {
			application: 'corporate-organization-frontend',
			userType: 'Customer',
			userid,
			email,
			account_id: accountId,
			language: Cookies.get('tmpl_lang') || 'en',
		});
	}
};
