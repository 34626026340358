import { EnvInstance } from './env';

const urlObj = {
	ACCOUNT_APP_URL: '',
	ACCOUNT_MANAGEMENT_API_URL: '',
	BILLING_URL: '',
	BILLING_V2_URL: '',
	IP_MANAGEMENT_API_URL: '',
	APP_DOMAIN: '',
	LOGIN_URL: '',
	NOTIFICATION_BANNER_API_URL: '',
};

export const getEnvVar = (key) => {
	if (APP_ENV === 'local') {
		if (['IP_MANAGEMENT_API_URL', 'ACCOUNT_MANAGEMENT_API_URL'].includes(key)) return 'https://local-corporate.51b.dev/api';
		return 'https://local-corporate.51b.dev';
	}

	return EnvInstance.urls[key];
};

const URLS = new Proxy(urlObj, {
	get(target, prop) {
		return getEnvVar(prop);
	}
});

export default URLS;
