export const APPLICATION_TITLE = 'Brevo';

// Pagination
export const DEFAULT_PAGE = 1;
export const DEFAULT_LIMIT_PER_PAGE = 10;
export const DEFAULT_SUBACCOUNT_LIMIT_PER_IP = 10;
export const SUBORGANIZATIONS_USERS_PER_PAGE = 20;

// Form validation
export const BLANK = 'blank';

// Plan quota display
export const USERS = 0;

// Plan customization
export const MIN_EMAILS = 0;
export const MIN_USERS = 0;
export const MIN_INBOXES = 0;
export const MIN_LANDING_PAGES = 0;
export const CONSUMABLE_NO_LIMIT = -1;
export const MAX_VALUE_ERROR = 'maxValueError';
export const MIN_VALUE_ERROR = 'minValueError';
export const USED_VALUE_ERROR = 'usedValueError';
export const EMAIL_USED_VALUE_ERROR = 'emailUsedValueError';
export const USER_USED_VALUE_ERROR = 'userUsedValueError';
export const INBOX_USED_VALUE_ERROR = 'inboxUsedValueError';
export const LP_USED_VALUE_ERROR = 'lpUsedValueError';
export const INCREMENT_STEP_ERROR = 'incrementStepError';

// Subaccount UserManagement
export const ACTION_TYPE_ANY = 'any';
export const CONNECTED_SUBACCOUNT = 'connected';
export const NON_CONNECTED_SUBACCOUNT = 'nonConnected';
export const NEW_USER = 'newUser';
export const EXISTING_SUBACCOUNT = 'existingSubaccount';
export const SUBACCOUNT_QUOTA_EXHAUSTED = 'sub_account_quota_exhausted';
export const MASTER_NOT_ALLOWED = 'master_not_allowed';

// Active Tab
export const SUBACCOUNT_TAB = 'subAccounts';
export const IP_MANAGEMENT_TAB = 'ipManagement';
export const ENABLED = 'enabled';

// Subaccount entV2 listing
export const REMAINING_DISPLAY_COUNT = 4;
export const MAX_IPS_TAGS_COUNT = 3;
export const MAX_APPS_TAGS_COUNT = 3;
export const MAX_RETRY_LIMIT = 3;
export const DEFAULT_DISPLAY_VALUE = 0;
export const MIN_ADD_ACCOUNT_LIMIT = 0;

// Sub organization consumables
export const WP_SUBSCRIBERS = 'wp_subscribers';
export const WP_IMPRESSIONS = 'wp_impressions';
export const PUSH = 'push';
export const SMS = 'sms';
export const WHATSAPP = 'whatsapp';
export const LANDING_PAGES = 'landing_pages';
export const EXTERNAL_FEEDS = 'external_feeds';

// Group management
export const MAX_GROUPS_PER_ROW = 2;
export const GROUP_NAME_MAX_LENGTH = 10;
export const MAX_GROUPS_BADGE_COUNT = 1;
export const MAX_GROUPS_IN_POPOVER = 6;

// BillingV2
export const DIRECT_DEBIT = 'direct_debit';
export const EUR = 'EUR';
export const localizationTypes = {
	INTERNATIONAL: 'international',
	GERMAN: 'german',
	US_ZONE: 'us-zone'
};

// IP management
export const SENDERS_IN_USE_ERROR = 'ip_senders_exist_with_other_ips';
