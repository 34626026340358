import { snackbarConstants } from '@dtsl/react';
import Cookies from 'js-cookie';
import { DateUtils } from '@dtsl/dateutils';
import { UPDATE_NOTIFICATION } from '../actions/actionTypes';
import { genericExceptionHandler } from '../actions/axios';
import { ERROR, ERROR_RESPONSE, UNKNOWN_ERROR } from '../constants/notification';

export function flattenMessages(nestedMessages, prefix = '') {
	return Object.keys(nestedMessages).reduce((messages, key) => {
		const value = nestedMessages[key];
		const prefixedKey = prefix ? `${prefix}.${key}` : key;
		if (typeof value === 'string') {
			messages[prefixedKey] = value;
		} else {
			Object.assign(messages, flattenMessages(value, prefixedKey));
		}
		return messages;
	}, {});
}

export const formatNumber = (number = 0, minimumFractionDigits = 0, maximumFractionDigits = 0) => {
	const language = Cookies.get('tmpl_lang') || 'en';
	return number.toLocaleString(language, {
		minimumFractionDigits,
		maximumFractionDigits
	});
};

export const capitalizeFirstChar = (str) => {
	if (!str) return '';
	return str.slice(0, 1).toUpperCase() + str.slice(1);
};

export const calcPercentage = (available, total) => {
	return (available / total) * 100;
};

export const findFrequencyOfCharacters = (text) => {
	const count = {};
	text.split('').forEach((char) => {
		count[char] ? count[char]++ : (count[char] = 1);
	});
	return count;
};

export const findMaxInArray = (array) => {
	let max = array[0];
	for (let i = 1; i < array.length; i++) {
		const value = array[i];
		max = value > max ? value : max;
	}
	return max;
};

export const isArrayEqual = (array1, array2) => {
	if (array1.length !== array2.length) return false;

	if (JSON.stringify(array1.sort()) !== JSON.stringify(array2.sort())) return false;

	return true;
};

export const parseToInt = (value, base = 10) => {
	const parsedValue = parseInt(value, base);
	if (Number.isNaN(parsedValue)) return 0;
	return parsedValue;
};

export const checkKeyIsEnterOrSpace = (key) => {
	return key === 'Enter' || key === ' ';
};

export function maskKey(str) {
	return str.toString().substr(-6).padStart(13, '*');
}

export const showNotificationPayload = (type, formattedMessageId, messageParams = {}) => {
	let key = formattedMessageId;

	if (!key) {
		key = type === ERROR ? `${ERROR_RESPONSE}.${UNKNOWN_ERROR}` : '';
	}

	return {
		type: UPDATE_NOTIFICATION,
		payload: {
			showNotification: true,
			type,
			formattedMessageId: key,
			messageParams
		}
	};
};

export const removeNotificationPayload = () => {
	return {
		type: UPDATE_NOTIFICATION,
		payload: {
			showNotification: false,
			type: '',
			formattedMessageId: ''
		}
	};
};

export const errorHandler = ({
	dispatch,
	callback,
	callbackParamsList,
	error,
	snackbar,
	intl,
	errorKey = UNKNOWN_ERROR
}) => {
	genericExceptionHandler(error, () => {
		snackbar({
			variant: 'default',
			type: snackbarConstants.ERROR,
			message: intl.formatMessage({ id: `errorResponse.${errorKey}` }),
			duration: 5000,
			loading: false,
			showCloseButton: true,
			handleClose: () => {}
		});
		dispatch(callback(...callbackParamsList, false));
	});
};

export const getTotalPlanAmount = (planPrice, planPeriodUnit, planPeriod) => {
	if (planPeriodUnit === 'year') return planPrice * planPeriod * 12;
	return planPrice * planPeriod;
};

export const commonDateUtils = (
	requiredDate,
	{
	    isTimeNeeded = false,
		isUnixTimeStamp = false,
		is12HourFormat = true,
		isShorthandType = true,
		dateVariantType = 'tertiary',
		dateFormatCategory = 'long',
		timeZone = 'Asia/Kolkata'
	}
) => {
	const locale = Cookies.get('tmpl_lang') || 'en';

	const { formatDate, formatDateAndTime } = new DateUtils(timeZone, locale);

	const parsedDate = isUnixTimeStamp ? requiredDate * 1000 : requiredDate;

	if (isTimeNeeded) {
		return formatDateAndTime(parsedDate, { is12Hour: is12HourFormat, dateVariant: dateVariantType });
	}
	return formatDate(parsedDate,
		{ dateFormatType: dateFormatCategory, dateVariant: dateVariantType, isShorthand: isShorthandType });
};

// For a given country_id, return the country object
export const getCountryById = (countryId, countriesList = []) => {
	return countriesList.filter(({ country_id }) => countryId === +country_id)[0] || {};
};

// For a given country code, return the country_id
export const getCountryIdByCode = (countryCode = '', countriesList = []) => {
	return +countriesList.filter(({ code }) => code === countryCode)[0]?.country_id;
};

// Remove multiple instances of countries having same country name
export const getUniqueCountries = (countries) => {
	const map = new Map();
	countries.forEach((country) => {
		if (!map.has(country.name)) map.set(country.name, country);
	});
	return [...map.values()];
};
